<template>
<div>
    <!-- 通道管理》预付费通道余额》消费详单 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" fullscreen :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>消费详单</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="daytime" label="时间" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="num" label="成功条数" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="money" label="计费金额" min-width="200"  align="center"> </el-table-column>
                </el-table>
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
                
            
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:0,//通道id
         prop_channel_name:"",//通道名
    },
    watch:{//监听props
        prop_change_i(val){

            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取数据-请求接口获取
                    this.getData();
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度
            
            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度

            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化
            this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化
        },


        //提交
        getData(){
            
            
            API.ChannelServlet({
                param:'channelPayDetail',
                channel_id:this.prop_channel_id,
                indexPage:this.indexPage,
                pageSize:this.pageSize,
            }).then((res) => {
                if (res.recode === 0) {
                   
                    this.tableData = res.list;
                    this.tableData_total = res.total_num;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                }
            });
        },

        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.getData();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.getData();
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}


</style>
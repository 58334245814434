<template>
    <!-- 通道管理》预付费通道余额  -->
    <div id="app_state_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
            </div>

            <div class="head_top_title">预付费通道余额</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 主体内容 -->
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="id" label="通道编号" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="channel_name" label="通道名称" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="money" label="截止昨日余额(元)" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="lasttime" label="最近充值时间" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="lastMoney" label="最近充值金额(元)" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="pay" label="昨日消费(元)" min-width="80"  align="center"> </el-table-column>
                    
                    <el-table-column   label="详单"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" style="color:#67C23A"  @click="rechargeDetail(scope.row.id,scope.row.channel_name)">充值详单</el-button>
                            <el-button type="text" size="mini" style=""  @click="consumeDetail(scope.row.id,scope.row.channel_name)">消费详单</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>

            

        </el-row>

        
        

    <!--充值详单-列表 组件 -->
    <ChannelFeeRechargeDetailList :prop_change_i="prop_recharge_detail_i" :prop_channel_id="prop_recharge_detail_id" :prop_channel_name="prop_recharge_detail_id_name"
      ></ChannelFeeRechargeDetailList>

    <!--消费详单-列表 组件 -->
    <ChannelFeeConsumeDetailList :prop_change_i="prop_consume_detail_i" :prop_channel_id="prop_consume_detail_id" :prop_channel_name="prop_consume_detail_id_name"
      ></ChannelFeeConsumeDetailList>


    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ChannelFeeRechargeDetailList from './channel_fee_recharge_detail_list.vue';//组件 充值详单 列表
import ChannelFeeConsumeDetailList from './channel_fee_consume_detail_list.vue';//组件 消费详单 列表
//import axios from "axios";
export default {

    components: {
        ChannelFeeRechargeDetailList,
        ChannelFeeConsumeDetailList
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据



            //充值详单列表
            prop_recharge_detail_i:0,//组件 弹出框是否可见
            prop_recharge_detail_id:0,//通道id
            prop_recharge_detail_id_name:"",//通道名

            //消费详单列表
            prop_consume_detail_i:0,//组件 弹出框是否可见
            prop_consume_detail_id:0,//通道id
            prop_consume_detail_id_name:"",//通道名

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },

    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },


        //查询
        getData(){
            //请求接口
            API.ChannelServlet({
                param: "channelFee",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },
        //充值详单-列表
        rechargeDetail(channel_id,channel_name){
            this.prop_recharge_detail_id = channel_id;//通道id
            this.prop_recharge_detail_id_name = channel_name;//通道名
            //充值详单列表
            this.prop_recharge_detail_i ++;//组件 弹出框是否可见
        },

        //消费详单-列表
        consumeDetail(channel_id,channel_name){
            this.prop_consume_detail_id = channel_id;//通道id
            this.prop_consume_detail_id_name = channel_name;//通道名
            //消费详单列表
            this.prop_consume_detail_i ++;//组件 弹出框是否可见
        },
        

        //返回
        goBack() {
            API.router_to("/channel_index");
        }
    }
};

</script>

<style scoped>


</style>